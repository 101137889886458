import React from 'react';
import './FullPage.spinner.styles.css';
const Landing: React.FC = () => {
  return (
    <div className="overlay">
      <div className="loader">
        <span />
        <span />
      </div>
    </div>
  );
};

export default Landing;

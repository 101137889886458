import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Table from '../components/Returns/SelectQuantity';
import Review from '../components/Review/index';
import ReasonOfReturn from '../components/Returns/ReasonOfReturn';
import SideSpinner from '../components/Spinner/Side.spinner';
import FullSpinner from '../components/Spinner/FullPage.spinner';
import {
  ReturnReasonNoteObjectTypes,
  SerialNumberArray,
  FileReturnRequest,
  RefObject,
  RMAReason,
} from '../interfaces/order';
import { Card, Modal } from 'antd';
import axios from 'axios';

const loadingAlert = (alert: string, time: number) => {
  const hide = message.loading(alert, 0);
  // Dismiss manually and asynchronously
  setTimeout(hide, time);
};
const successAlert = (alert: string) => {
  message.success(alert);
};
const errorAlert = (alert: string) => {
  message.error(alert);
};

type LocationState = {
  order_id: {
    path: string;
  };
};

let CustomPackage: any = [];
const Landing: React.FC = () => {
  const emptyProductsArrayRef = useRef<RefObject>(null);
  const [productsAfterPassedStepOne, setProductsAfterPassedStepOne] =
    useState(null);
  const [itemSerialNumbers, setItemSerialNumbers] = useState<
    SerialNumberArray[]
  >([]);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [showStep, setShowStep] = useState(1);
  const [order, setOrder] = useState(null);
  const [orderNo, setOrderNo] = useState(null);
  const [orderOwner, setOrderOwner] = useState(null);
  const [packages, setPackages] = useState<any>([]);
  const [products, setProducts] = useState<ReturnReasonNoteObjectTypes[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { order_id } = location.state as LocationState;
  useEffect(() => {
    RetriveOrder();
  }, []);
  function RetriveOrder() {
    loadingAlert('Fetching Order Detail...', 1500);
    axios
      .get(`/api/v1/shopify/retriveOrder/${order_id}`)
      .then((response) => {
        const sales_order = response?.data?.response?.salesorder;
        setOrder(sales_order);
        setOrderNo(sales_order.reference_number);
        setOrderOwner(sales_order.customer_name);
        setPackages(sales_order.packages);
        retrivePackages(sales_order.packages);
      })
      .catch((err) => console.log(err));
  }

  function RetriveShipment() {
    loadingAlert('Fetching Shipment Detail...', 1500);
    axios
      .get(`/api/v1/shopify/retriveShipment/${order_id}`)
      .then((response) => {
        const shipment = response?.data?.response;
      })
      .catch((err) => console.log(err));
  }

  function retriveSerialNumberFromPackage(serialNumberItems: any[]) {
    const serialNumbers = [];
    for (let index = 0; index < serialNumberItems.length; index++) {
      const serailNumber: SerialNumberArray = {
        so_line_item_id: serialNumberItems[index].so_line_item_id,
        serial_numbers: serialNumberItems[index].serial_numbers,
      };
      if (
        itemSerialNumbers.some(
          (e) => e.so_line_item_id === serailNumber.so_line_item_id
        )
      ) {
        return;
      } else {
        serialNumbers.push(serailNumber);
      }
    }
    // console.log('serialNumbers: ', serialNumbers);
    setItemSerialNumbers(serialNumbers);
  }

  function mergePackages(currentPackage: any, count: number) {
    const lineItems: any = currentPackage?.data?.response?.package?.line_items;
    for (let x = 0; x < lineItems.length; x++) {
      const itemIs: any = lineItems[x];
      if (count == 0) {
        CustomPackage.push(itemIs);
      } else {
        if (
          CustomPackage.some(
            (e: any) => e.so_line_item_id === itemIs.so_line_item_id
          )
        ) {
          for (let i = 0; i < CustomPackage.length; i++) {
            if (CustomPackage[i].so_line_item_id === itemIs.so_line_item_id) {
              for (let j = 0; j < itemIs?.serial_numbers.length; j++) {
                CustomPackage[i]?.serial_numbers.push(
                  itemIs?.serial_numbers[j]
                );
              }
              CustomPackage[i].quantity =
                CustomPackage[i].quantity + itemIs.quantity;
            }
          }
        } else {
          CustomPackage.push(itemIs);
        }
      }
    }
    return CustomPackage;
  }

  function retrivePackages(packages: any) {
    loadingAlert('Fetching Shiped Pacakges...', 2000);
    setSpinner(true);
    let count = 0;
    for (let i = 0; i < packages.length; i++) {
      const id = packages[i].package_id;
      axios
        .get(`/api/v1/shopify/retrivePackage/${id}`)
        .then((response) => {
          const result: any = mergePackages(response, count);
          count++;
          if (count == packages.length) {
            retriveSerialNumberFromPackage(result);
            CustomPackage = [];
            setSpinner(false);
          }
        })
        .catch((err) => {
          count++;
          if (count == packages.length) {
            CustomPackage = [];
            setSpinner(false);
          }
          console.log(err);
        });
    }
  }

  async function FileReturn(
    salesorder_id: string,
    body: FileReturnRequest,
    ownerName: string
  ) {
    const newArray: RMAReason[] = [];
    setSpinner(true);
    axios
      .post(`/api/v1/shopify/fileReturn/${salesorder_id}`, body)
      .then((response) => {
        console.log(response?.data?.response?.salesreturn);
        for (let i = 0; i < body?.custom_fields.length; i++) {
          const object = {
            record_name: body?.custom_fields[i].Note,
            cf_reason_for_return: body?.custom_fields[i].Reason,
            cf_customer_name: ownerName,
            cf_rma_date: response?.data?.response?.salesreturn?.created_date,
            cf_rma_number:
              response?.data?.response?.salesreturn?.salesreturn_number,
            cf_serial_number: body?.custom_fields[i].Product,
          };
          newArray.push(object);
        }
        loadingAlert('Saving RMA Notes and Reasons...', 2000);
        axios
          .post('/api/v1/shopify/rma_reason', newArray)
          .then((res) => {
            emptyProductsArrayRef.current?.emptyProductsArray();
            setSpinner(false);
            successAlert(response?.data?.message);
            navigate(`/invoice`, {
              state: {
                salesReturn: body,
                salesReturnDetail: response?.data?.response?.salesreturn,
              },
            });
          })
          .catch((error) => {
            errorAlert(error?.response?.data?.message);
          });
      })
      .catch((err) => {
        setSpinner(false);
        errorAlert(err?.response?.data?.message);
      });
  }

  function goToReview(products: ReturnReasonNoteObjectTypes[]) {
    // setShowStep(2);
    setVisible(true);
    setProducts(products);
  }

  return (
    <div
      style={{
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {spinner && <FullSpinner />}
      {order ? (
        <div
          className="site-card-border-less-wrapper"
          style={{ width: '100% !important' }}
        >
          <Card
            title={
              <div>
                {showStep == 2 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width={13}
                    height={13}
                    viewBox="0 0 172 172"
                    style={{ fill: '#000000', cursor: 'pointer' }}
                    onClick={() => setShowStep(1)}
                  >
                    <g
                      fill="none"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth={1}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit={10}
                      strokeDashoffset={0}
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                      style={{ mixBlendMode: 'normal' }}
                    >
                      <path d="M0,172v-172h172v172z" fill="none" />
                      <g fill="#000000">
                        <path d="M157.66667,71.66667h-101.18717l31.88607,-26.57056c3.05143,-2.54403 4.80111,-6.28133 4.80111,-10.25309c0,-7.35563 -5.98389,-13.34302 -13.34652,-13.34302h-0.97982c-3.16341,0 -6.22884,1.12679 -8.65039,3.17741l-68.93018,58.5931c-0.79785,0.67887 -1.25976,1.67969 -1.25976,2.72949c0,1.0498 0.46191,2.05062 1.25977,2.72949l68.93717,58.5966c2.41455,2.04712 5.47998,3.17391 8.64339,3.17391h0.97982c7.36263,0 13.34652,-5.98739 13.34652,-13.34302c0,-3.97176 -1.74967,-7.70907 -4.80111,-10.25309l-31.88607,-26.57056h101.18717c7.90153,0 14.33333,-6.4283 14.33333,-14.33333c0,-7.90503 -6.4318,-14.33333 -14.33333,-14.33333z" />
                      </g>
                    </g>
                  </svg>
                )}
                <span style={{ marginLeft: '8px' }}>Order #{orderNo}</span>
                <span style={{ float: 'right' }}>{orderOwner}</span>
              </div>
            }
            headStyle={{ backgroundColor: '#d3d3d3', fontWeight: 700 }}
            bordered={false}
          >
            {showStep == 1 && (
              <Table
                orders={order}
                serialNumber={itemSerialNumbers}
                goToReview={(products: ReturnReasonNoteObjectTypes[]) =>
                  goToReview(products)
                }
                FileReturn={(
                  salesorder_id: string,
                  body: FileReturnRequest,
                  ownerName: string
                ) => FileReturn(salesorder_id, body, ownerName)}
                ref={emptyProductsArrayRef}
              />
            )}
            <Modal
              title="Preview"
              centered
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              width={1000}
              footer={null}
            >
              <Review products={products} />
            </Modal>
          </Card>
        </div>
      ) : (
        <SideSpinner />
      )}
    </div>
  );
};

export default Landing;

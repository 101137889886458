import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../components/Review/index';
import { FileReturnRequest } from '../interfaces/order';
import FullPageSpinner from '../components/Spinner/FullPage.spinner';
import axios from 'axios';
import { Card, message } from 'antd';
const successAlert = (alert: string) => {
  message.success(alert);
};
const errorAlert = (alert: string) => {
  message.error(alert);
};
type LocationState = {
  product: {
    path: string;
  };
};
const Review: React.FC = () => {
  const [spinner, setSpinner] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state as LocationState;
  function FileReturn(salesorder_id: string, body: FileReturnRequest) {
    setSpinner(true);
    axios
      .post(`/api/v1/shopify/fileReturn/${salesorder_id}`, body)
      .then((response) => {
        setSpinner(false);
        successAlert(response?.data?.message);
        navigate(`/invoice`, {
          state: {
            salesReturn: body,
            salesReturnDetail: response?.data?.response?.salesreturn,
          },
        });
      })
      .catch((err) => {
        setSpinner(false);
        errorAlert(err?.response?.data?.message);
      });
  }

  return (
    <div
      style={{
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {spinner && <FullPageSpinner />}
      <div
        className="site-card-border-less-wrapper"
        style={{ width: '100% !important' }}
      >
        <Card
          title={
            <div>
              <span>Order #1065</span>
              <span style={{ float: 'right' }}>samrantariq7949@gmail.com</span>
            </div>
          }
          headStyle={{ backgroundColor: '#d3d3d3', fontWeight: 700 }}
          bordered={false}
        >
          <Table products={product} />
        </Card>
      </div>
    </div>
  );
};

export default Review;

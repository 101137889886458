import React from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import './styles.css';
const FormItem = Form.Item;

interface VerifyOrderProps {
  VerifyOrder: (email: string, order_no: string, invoice_amount: string) => void;
}

const VerifyOrder = (props: VerifyOrderProps) => {
  const onFinish = (values: any) => {
    const { email, order_no, invoice_amount } = values;
    props.VerifyOrder(email, order_no, invoice_amount);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Row style={{ marginTop: 60, textAlign: 'left' }}>
        <Col xs={0} sm={2} md={8} lg={8} xl={8} xxl={8}></Col>
        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <span>Email Address:</span>
            <FormItem
              name="email"
              rules={[{ required: true, message: 'Please enter your email!' }]}
            >
              <Input placeholder={'Username'} />
            </FormItem>
            <span>Order Number:</span>
            <FormItem
              name="order_no"
              rules={[
                { required: true, message: 'Please enter your Order No!' },
              ]}
            >
              <Input type="item" placeholder={'ItemNumber'} />
            </FormItem>
            <span>Invoice Total Amount:</span>
            <FormItem
              name="invoice_amount"
              rules={[
                { required: true, message: 'Please enter your Invoice Total Amount!' },
              ]}
            >
              <Input type="item" placeholder={'Invoice Total Amount'} />
            </FormItem>
            <Row className="verify-form-button-row">
              <Button
                type="primary"
                htmlType="submit"
                className="verify-form-button"
                //loading={loading.effects.login}
              >
                Start
              </Button>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={2} md={8} lg={8} xl={8} xxl={8}></Col>
      </Row>
    </div>
  );
};

export default VerifyOrder;

import React from 'react';
import './styles.css';
import logo from '../../assets/logo.png';
import { Row, Col, Typography } from 'antd';
import Icon from 'antd/lib/icon';
import { PhoneFilled, MailFilled, PushpinFilled } from '@ant-design/icons';

const { Paragraph } = Typography;
function Footer() {
  return (
    <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
        <div
          className="footer-logo"
          style={{
            margin: 0,
            marginTop: '-4em',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <a href="https://2ndlifephones.com/" target="_blank" rel="noreferrer">
            <img style={{ width: 180 }} src={logo} />
          </a>
        </div>
        <p style={{ textAlign: 'center' }}>
          Distributor for new and used consumer <br /> electronics based in Los
          Angeles
        </p>
      </Col>
      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
        <div className="footer-top-2-col-1">
          <h4>Your Account</h4>
          <ul>
            <li>
              <a
                href="https://2ndlifephones.com/account/addresses"
                target="_blank"
                rel="noreferrer"
              >
                Shipping Address
              </a>
            </li>
            <li>
              <a
                href="https://2ndlifephones.com/collections/all?gf_11810=Fully%20Functional"
                target="_blank"
                rel="noreferrer"
              >
                Product Lines
              </a>
            </li>
            <li>
              <a
                href="https://2ndlifephones.com/account"
                target="_blank"
                rel="noreferrer"
              >
                Order History
              </a>
            </li>
            <li>
              <a
                href="https://2ndlifephones.com/pages/forgot-password"
                target="_blank"
                rel="noreferrer"
              >
                Forgot Password
              </a>
            </li>
          </ul>
        </div>
      </Col>
      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
        <div className="footer-top-2-col-2">
          <h4>Company</h4>
          <ul>
            <li>
              <a
                href="https://2ndlifephones.com/pages/quality-process"
                target="_blank"
                rel="noreferrer"
              >
                Quality and Product Process
              </a>
            </li>
            <li>
              <a
                href="https://2ndlifephones.com/pages/grading"
                target="_blank"
                rel="noreferrer"
              >
                Grading Guidelines
              </a>
            </li>
            <li>
              <a
                href="https://2ndlifephones.com/pages/returns"
                target="_blank"
                rel="noreferrer"
              >
                Return Policy and Process
              </a>
            </li>
            <li>
              <a
                href="https://2ndlifephones.com/pages/orderprocess"
                target="_blank"
                rel="noreferrer"
              >
                Order Process
              </a>
            </li>
            <li>
              <a href="#">FAQ</a>
            </li>
            <li>
              <a
                href="https://2ndlifephones.com/pages/contact"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </Col>
      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
        <div className="footer-top-2-col-2" style={{ marginLeft: '1.3em' }}>
          <h4>Help &amp; Support:</h4>
          <ul>
            <li>
              <PhoneFilled className="icon" />
              <span className="icon_label">+1 650-394-7310</span>
            </li>
            <li>
              <MailFilled className="icon" />
              <span className="icon_label">support@2ndlifephones.com</span>
            </li>

            <li>
              <PushpinFilled className="icon" />
              <span className="icon_label">
                222 N Pacific Coast Highway, Suite 2000, El Segundo, CA, 90245
              </span>
            </li>
            <li>
              <a href="https://www.facebook.com/2ndlifephones/" target="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width={24}
                  height={24}
                  viewBox="0 0 172 172"
                  style={{ fill: '#000000' }}
                >
                  <g
                    fill="none"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth={1}
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit={10}
                    strokeDashoffset={0}
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: 'normal' }}
                  >
                    <path d="M0,172v-172h172v172z" fill="none" />
                    <g fill="#333333">
                      <path d="M86,14.33333c-39.5815,0 -71.66667,32.08517 -71.66667,71.66667c0,35.9265 26.4665,65.5965 60.9525,70.778v-51.78633h-17.73033v-18.84117h17.73033v-12.5345c0,-20.75467 10.11217,-29.8635 27.36233,-29.8635c8.26317,0 12.62767,0.60917 14.69883,0.88867v16.44033h-11.76767c-7.32433,0 -9.88283,6.9445 -9.88283,14.7705v10.2985h21.46417l-2.90967,18.84117h-18.54733v51.93683c34.9805,-4.73717 61.963,-34.65083 61.963,-70.9285c0,-39.5815 -32.08517,-71.66667 -71.66667,-71.66667z" />
                    </g>
                  </g>
                </svg>
              </a>
              <a href="https://www.instagram.com/2nd.lifephones/" target="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width={24}
                  height={24}
                  viewBox="0 0 172 172"
                  style={{ fill: '#000000' }}
                >
                  <g
                    fill="none"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth={1}
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit={10}
                    strokeDashoffset={0}
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: 'normal' }}
                  >
                    <path d="M0,172v-172h172v172z" fill="none" />
                    <g fill="#333333">
                      <path d="M57.33333,21.5c-19.78717,0 -35.83333,16.04617 -35.83333,35.83333v57.33333c0,19.78717 16.04617,35.83333 35.83333,35.83333h57.33333c19.78717,0 35.83333,-16.04617 35.83333,-35.83333v-57.33333c0,-19.78717 -16.04617,-35.83333 -35.83333,-35.83333zM129,35.83333c3.956,0 7.16667,3.21067 7.16667,7.16667c0,3.956 -3.21067,7.16667 -7.16667,7.16667c-3.956,0 -7.16667,-3.21067 -7.16667,-7.16667c0,-3.956 3.21067,-7.16667 7.16667,-7.16667zM86,50.16667c19.78717,0 35.83333,16.04617 35.83333,35.83333c0,19.78717 -16.04617,35.83333 -35.83333,35.83333c-19.78717,0 -35.83333,-16.04617 -35.83333,-35.83333c0,-19.78717 16.04617,-35.83333 35.83333,-35.83333zM86,64.5c-11.87412,0 -21.5,9.62588 -21.5,21.5c0,11.87412 9.62588,21.5 21.5,21.5c11.87412,0 21.5,-9.62588 21.5,-21.5c0,-11.87412 -9.62588,-21.5 -21.5,-21.5z" />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
}

export default Footer;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Radio, Select, Form, Button, Input, Row } from 'antd';
import { ReasonNoteArray, LineItem, ReviewTypes } from '../../interfaces/order';
import './styles.css';
const { TextArea } = Input;

const Table: React.FC<ReviewTypes> = (props) => {
  const line_items: LineItem[] = [];
  const custom_fields: ReasonNoteArray[] = [];
  let salesorder_id: string = '';
  const navigate = useNavigate();

  function saveLineItems() {
    let flag = true;
    const products = props?.products;
    for (let i = 0; i < products.length; i++) {
      if (products[i].returnQuantity < 1) {
        continue;
      }
      if (flag) {
        salesorder_id = products[i]?.salesorder_id;
        flag = false;
      }
      const returnDetail: ReasonNoteArray[] = products[i]?.returnDetails;
      const line_item = {
        item_id: products[i].item_id,
        salesorder_item_id: products[i].line_item_id,
        non_receive_quantity: 0,
        quantity: products[i].returnQuantity,
        warehouse_id: products[i].warehouse_id,
      };
      line_items.push(line_item);
      for (let j = 0; j < returnDetail.length; j++) {
        const custom_field = {
          Product: returnDetail[j].Product,
          Reason: returnDetail[j].Reason,
          Note: returnDetail[j].Note,
          Sku: returnDetail[j].Sku,
        };
        custom_fields.push(custom_field);
      }
    }
  }
  return (
    <div>
      <div className="content" id="content">
        <div id="invoice-bot">
          <div id="table">
            <div
              className="content"
              id="content"
              style={{ marginBottom: '1em' }}
            >
              {props.products.map((product: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {product?.returnQuantity > 0 && (
                      <>
                        <table className="outer_variant_table">
                          <tbody>
                            <tr className="outer_variant_table_tr">
                              <th className="outer_variant_table_th">
                                {product.name}
                              </th>
                              <th className="outer_variant_table_th">
                                SKU : {product.sku}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        <table className="variant_table">
                          <tbody>
                            <tr className="variant_table_tr">
                              <th className="variant_table_th">IMEI</th>
                              <th className="variant_table_th">Reason Name</th>
                              <th className="variant_table_th">Description </th>
                            </tr>
                            {product?.returnDetails.map(
                              (detail: ReasonNoteArray, index: number) => {
                                return (
                                  <tr className="variant_table_tr">
                                    <td className="variant_table_td">
                                      {detail.Product}
                                    </td>
                                    <td className="variant_table_td">
                                      {detail.Reason}
                                    </td>
                                    <td className="variant_table_td">
                                      {detail.Note}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;

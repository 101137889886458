import React from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Landing from './pages/Landing';
import Returns from './pages/Returns';
import Review from './pages/Review';
import Invoice from './pages/Invoice';
import FooterContent from './components/Home/Footer';
import { PageHeader, Layout, Row, Col } from 'antd';
const { Header, Footer, Sider, Content } = Layout;

function App() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <PageHeader
        backIcon={false}
        className="site-page-header"
        title={
          <a
            href="https://2ndlifephones.com/"
            target="_blank"
            style={{ cursor: 'pointer', color: 'black' }}
            rel="noreferrer"
          >
            2ND LIFE PHONES
          </a>
        }
        subTitle="Sales Return"
      />
      <Content style={{ padding: '0 50px' }}>
        <Routes>
          <Route path="/" element={<Landing />}></Route>
          <Route path="/returns" element={<Returns />}></Route>
          <Route path="/review" element={<Review />}></Route>
          <Route path="/invoice" element={<Invoice />}></Route>
        </Routes>
      </Content>
      <Footer style={{ background: 'white' }}>
        <FooterContent />
      </Footer>
    </div>
  );
}
export default App;

import axios from 'axios';

axios.defaults.baseURL = 'https://backend.2ndlifephonesreturns.com';
// 'https://backend.2ndlifephonesreturns.com';
// axios.defaults.baseURL = 'http://localhost:3001';

// Where you would set stuff like your 'Authorization' header, etc ...
// .~ instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff
// .~ instance.interceptors.request...

// Override timeout default for the library
// Now all requests using this instance will wait 2.5 seconds before timing out
// .~ instance.defaults.timeout = 2500;

// Override timeout for this request as it's known to take a long time
// .~ instance.get('/longRequest', {
// .~ timeout: 5000
// .~ });

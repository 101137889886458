import React, { useState, forwardRef, useImperativeHandle, Ref } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Select, Input, Col, message } from 'antd';
import './styles.css';
import {
  QuanttityObjectTypes,
  ReturnReasonNoteObjectTypes,
  Orders,
  ReasonNoteArray,
  LineItem,
  RefObject,
} from '../../interfaces/order';
import { removeUnmatched } from '../../utils/methods.array';
import ReasonOfReturn from './ReasonOfReturn';
let productsArray: ReturnReasonNoteObjectTypes[] = [];
const { Option } = Select;
const { TextArea } = Input;

const errorAlert = (alert: string) => {
  message.error(alert);
};
interface SelectedSerialNumbersType {
  line_item_id: string;
  Product: string;
  serialNumber: string[];
}

interface Errors {
  serialNumber?: string;
  sku?: string;
  name?: string;
  message: string;
}

let selectedSerialNumber: SelectedSerialNumbersType[] = [];
let optionsSelected: any = [];
let savePreviousRecord: any = {};
const Table = forwardRef(
  (props: Orders, emptyProductsArrayRef: Ref<RefObject>) => {
    useImperativeHandle(emptyProductsArrayRef, () => ({ emptyProductsArray }));
    const line_items: LineItem[] = [];
    const custom_fields: ReasonNoteArray[] = [];
    let salesorder_id: string = '';
    const error: Errors[] = [];
    const navigate = useNavigate();
    const [productArray, setProductArray] = useState<QuanttityObjectTypes[]>(
      []
    );
    const [errors, setErrors] = useState<Errors[]>([]);
    const [selectedMultiSelectValues, setSelectedMultiSelectValues] =
      useState<any>([]);
    const [reRenderToggle, setReRenderToggle] = useState<boolean>(true);

    const { orders, goToReview, FileReturn } = props;

    function emptyMultiSelect(line_item_id: string, value: number) {
      console.log('line_item_id: ' + line_item_id + ', value: ' + value);
      const previousValue: any = selectedMultiSelectValues;
      for (let i = 0; i < previousValue.length; i++) {
        if (
          previousValue[i].line_item_id === line_item_id &&
          previousValue[i].serial_number.length > value
        ) {
          previousValue[i].serial_number = [];
        }
      }
      setSelectedMultiSelectValues(previousValue);
    }

    function refactorSelectedSerialNumber(line_item_id: string, value: number) {
      for (let i = 0; i < selectedSerialNumber.length; i++) {
        if (
          selectedSerialNumber[i].line_item_id === line_item_id &&
          selectedSerialNumber[i].serialNumber.length > value
        ) {
          selectedSerialNumber[i].serialNumber = [];
          for (let j = 0; j < productsArray.length; j++) {
            if (productsArray[j].line_item_id === line_item_id) {
              productsArray[j].returnDetails = [];
            }
          }
        }
      }
    }

    function handleChange(value: any, line_item_id: string) {
      // const arrayReasonNote: ReasonNoteArray[] = [];
      emptyMultiSelect(line_item_id, value.target.value);
      refactorSelectedSerialNumber(line_item_id, value.target.value);
      setReRenderToggle(!reRenderToggle);
      for (let index = 0; index < productsArray.length; index++) {
        if (productsArray[index].line_item_id == line_item_id) {
          productsArray[index].returnQuantity = value.target.value;
          for (let j = 0; j < productsArray[index].returnQuantity; j++) {
            // const object = {
            //   Product: productsArray[index].name + ' #' + (j + 1),
            //   Reason: '',
            //   Note: '',
            // };
            // arrayReasonNote.push(object);
            const objectSerialNumbers = {
              line_item_id: productsArray[index].line_item_id,
              Product: productsArray[index].name + ' #' + (j + 1),
              serialNumber: [],
            };
            if (
              selectedSerialNumber.some(
                (e) => e.line_item_id === productsArray[index].line_item_id
              )
            ) {
              continue;
            } else {
              selectedSerialNumber.push(objectSerialNumbers);
            }
          }
          // productsArray[index].returnDetails = arrayReasonNote;
        } else {
          continue;
        }
      }
      setProductArray(productsArray);
    }
    function showOptions(line_item_id: string) {
      const serialNumberArray: any = [];
      for (let i = 0; i < props?.serialNumber.length; i++) {
        if (props?.serialNumber[i].so_line_item_id == line_item_id) {
          serialNumberArray.push(props?.serialNumber[i].serial_numbers);
        }
      }
      if (serialNumberArray[0]) {
        return serialNumberArray[0];
      } else {
        return serialNumberArray;
      }
    }
    function saveSelectedQuantity(order: any, product: any) {
      if (productsArray.some((e) => e.line_item_id === product.line_item_id)) {
        return;
      } else {
        const object = {
          item_id: product.item_id,
          salesorder_id: order.salesorder_id,
          warehouse_id: product.warehouse_id,
          sku: product.sku,
          line_item_id: product.line_item_id,
          variant_id: product.variant_id,
          product_id: product.product_id,
          created_date: order.created_date,
          name: product.name,
          quantity: product.quantity,
          returnQuantity: 0,
          returnDetails: [],
        };
        productsArray.push(object);
        return;
      }
    }

    function getShippedQuantity(line_item_id: string) {
      let serialNumberLength: any = 0;
      for (let i = 0; i < props?.serialNumber.length; i++) {
        if (props?.serialNumber[i].so_line_item_id == line_item_id) {
          serialNumberLength = props?.serialNumber[i].serial_numbers.length;
        }
      }
      return serialNumberLength;
    }

    function serialNumberSelectHandleChange(value: any, line_item_id: string) {
      const obj = {
        line_item_id: line_item_id,
        serial_number: value,
      };
      if (optionsSelected.length == 0) {
        optionsSelected.push(obj);
      } else {
        for (let x = 0; x < optionsSelected.length; x++) {
          if (optionsSelected[x].line_item_id === line_item_id) {
            optionsSelected[x].serial_number = value;
          } else {
            optionsSelected.push(obj);
          }
        }
      }

      setSelectedMultiSelectValues(optionsSelected);
      testAddReasonNoteAlongWithSerialNumbers(optionsSelected, line_item_id);
      for (let i = 0; i < selectedSerialNumber.length; i++) {
        if (selectedSerialNumber[i].line_item_id === line_item_id) {
          selectedSerialNumber[i].serialNumber = value;
          setReRenderToggle(!reRenderToggle);
        }
      }
    }

    function testAddReasonNoteAlongWithSerialNumbers(
      optionsSelected: any,
      line_item_id: string
    ) {
      const copyOptionsSelectedSerialNumber = [];
      for (let i = 0; i < productsArray.length; i++) {
        if (productsArray[i].line_item_id === line_item_id) {
          for (let j = 0; j < optionsSelected.length; j++) {
            if (optionsSelected[j].line_item_id === line_item_id) {
              for (
                let k = 0;
                k < optionsSelected[j].serial_number.length;
                k++
              ) {
                const object = {
                  Product: optionsSelected[j].serial_number[k],
                  Sku: productsArray[i].sku,
                  Reason: '',
                  Note: '',
                };
                copyOptionsSelectedSerialNumber.push(object);
                if (
                  productsArray[i].returnDetails.filter(function (e) {
                    return e.Product === optionsSelected[j].serial_number[k];
                  }).length < 1
                ) {
                  productsArray[i].returnDetails.push(object);
                }
              }
            }
          }
          productsArray[i].returnDetails = removeUnmatched(
            productsArray[i].returnDetails,
            copyOptionsSelectedSerialNumber
          );
        }
      }
    }

    function showSerialNumbers(line_item_id: string) {
      const serialNumberArray: any = [];
      console.log('props?.serialNumber.length: ', props?.serialNumber.length);
      console.log('serialNumbers: ', props?.serialNumber);
      
      props?.serialNumber .map((anObjectMapped, index) => {
        if( anObjectMapped.so_line_item_id == line_item_id )
        {
          serialNumberArray.push( anObjectMapped.serial_numbers )
          console.log('newSerialNumbersArray: ', serialNumberArray);
        }
      })

      // for (let i = 0; i < props?.serialNumber.length; i++) {
      //   if (props?.serialNumber[i].so_line_item_id == line_item_id) {
      //     serialNumberArray.push(props?.serialNumber[i].serial_numbers);
      //   }
      //   console.log('newSerialNumbersArray: ', serialNumberArray);
      // }
      if (serialNumberArray[0]) {
        return serialNumberArray[0];
      } else {
        return serialNumberArray;
      }
    }
    function getSelectedSerialNumbers(line_item_id: string) {
      let serialNumbers: string[] = [];
      // console.log( 'selectedSerialNumber: ', selectedSerialNumber );
      for (let i = 0; i < selectedSerialNumber.length; i++) {
        console.log('i = ' + i)
        // console.log( selectedSerialNumber[i].line_item_id +' === ' + line_item_id );
        if (selectedSerialNumber[i].line_item_id === line_item_id) {
          // console.log( 'i: ' + ( i ) + ' => ' + selectedSerialNumber[i].line_item_id +' === ' + line_item_id );
          serialNumbers = selectedSerialNumber[i].serialNumber;
          // break;
        }
      }
      return serialNumbers;
    }

    function getReturnQuantity(line_item_id: string) {
      let returnQuantity: number = 0;
      for (let i = 0; i < productsArray.length; i++) {
        if (productsArray[i].line_item_id === line_item_id) {
          returnQuantity = productsArray[i].returnQuantity;
        }
      }
      return returnQuantity;
    }

    function getOptionsSelectedLength(line_item_id: string) {
      let length: number = 0;
      for (let i = 0; i < optionsSelected.length; i++) {
        if (optionsSelected[i].line_item_id === line_item_id) {
          length = optionsSelected[i].serial_number.length;
        }
      }
      return length;
    }

    function getOptionsSelectedSerialNumber(line_item_id: string) {
      let serialNumbers: number[] = [];
      for (let i = 0; i < optionsSelected.length; i++) {
        if (optionsSelected[i].line_item_id === line_item_id) {
          serialNumbers = optionsSelected[i].serial_number;
        }
      }
      return serialNumbers;
    }

    function getMultiSelectSelectedValues(line_item_id: string) {
      let value: any = [];
      for (let i = 0; i < selectedMultiSelectValues.length; i++) {
        if (selectedMultiSelectValues[i].line_item_id === line_item_id) {
          value = selectedMultiSelectValues[i].serial_number;
        }
      }
      return value;
    }

    function getPreviousReturnQuantityValue(line_item_id: string) {
      let value: number = 0;
      for (let i = 0; i < productsArray.length; i++) {
        if (productsArray[i].line_item_id === line_item_id) {
          value = productsArray[i].returnQuantity;
        }
      }
      return value;
    }

    function handleTextAreaChange(
      value: any,
      line_item_id: string,
      position: number,
      serialNumber: string
    ) {
      for (let index = 0; index < productsArray.length; index++) {
        if (productsArray[index].line_item_id == line_item_id) {
          for (let j = 0; j < productsArray[index].returnDetails.length; j++) {
            if (productsArray[index].returnDetails[j].Product == serialNumber) {
              productsArray[index].returnDetails[j].Note = value.target.value;
            }
          }
        } else {
          continue;
        }
      }
      setReRenderToggle(!reRenderToggle);
    }

    function handleInputChange(
      value: any,
      line_item_id: string,
      position: number,
      serialNumber: string
    ) {
      for (let index = 0; index < productsArray.length; index++) {
        if (productsArray[index].line_item_id == line_item_id) {
          for (let j = 0; j < productsArray[index].returnDetails.length; j++) {
            if (productsArray[index].returnDetails[j].Product == serialNumber) {
              productsArray[index].returnDetails[j].Reason = value.target.value;
            }
          }
        } else {
          continue;
        }
      }
      setReRenderToggle(!reRenderToggle);
    }

    function getReasonValue(
      line_item_id: string,
      position: number,
      serialNumber: string
    ) {
      let Reason = '';
      for (let index = 0; index < productsArray.length; index++) {
        if (productsArray[index].line_item_id == line_item_id) {
          for (let j = 0; j < productsArray[index].returnDetails.length; j++) {
            if (productsArray[index].returnDetails[j].Product == serialNumber) {
              Reason = productsArray[index].returnDetails[j].Reason;
            }
          }
        } else {
          continue;
        }
      }
      return Reason;
    }

    function getNoteValue(
      line_item_id: string,
      position: number,
      serialNumber: string
    ) {
      let Note = '';
      for (let index = 0; index < productsArray.length; index++) {
        if (productsArray[index].line_item_id == line_item_id) {
          for (let j = 0; j < productsArray[index].returnDetails.length; j++) {
            if (productsArray[index].returnDetails[j].Product == serialNumber) {
              Note = productsArray[index].returnDetails[j].Note;
            }
          }
        } else {
          continue;
        }
      }
      return Note;
    }

    function checkReasonNoteArray(values: ReturnReasonNoteObjectTypes[]) {

      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < values[i]?.returnDetails.length; j++) {

          const productSerialNo = values[i]?.returnDetails[j]?.Product
          const NoteTxt = document.getElementById("note_" + productSerialNo) as HTMLInputElement
          const ReasonTxt = document.getElementById("reason_" + productSerialNo) as HTMLInputElement

          // if (values[i]?.returnDetails[j]?.Note === '') 
          if (NoteTxt.value === '')
          {
            const object = {
              serialNumber: productSerialNo,
              sku: values[i]?.returnDetails[j]?.Sku,
              message: `Description missing for serail_number ${values[i]?.returnDetails[j]?.Product} against sku ${values[i]?.returnDetails[j]?.Sku}`,
            };
            error.push(object);
          }
          else {
            console.log('Note Else for Note_' + productSerialNo)
            values[i] .returnDetails[j] .Note = NoteTxt.value;
          }
          // if (values[i]?.returnDetails[j]?.Reason === '') 
          if (ReasonTxt.value === '')
          {
            const object = {
              serialNumber: values[i]?.returnDetails[j]?.Product,
              sku: values[i]?.returnDetails[j]?.Sku,
              message: `Reason missing for serail_number ${values[i]?.returnDetails[j]?.Product} against sku ${values[i]?.returnDetails[j]?.Sku}`,
            };
            error.push(object);
          }
          else {
            console.log('Reason Else for Reason_' + productSerialNo)
            
            values[i] .returnDetails[j] .Reason = ReasonTxt.value
          }
        }
      }
      console.log('product schema: ', values)
      setErrors(error);
    }

    function checkReturnQuantity(values: ReturnReasonNoteObjectTypes[]) {
      for (let i = 0; i < values.length; i++) {
        if (values[i]?.returnDetails.length != values[i]?.returnQuantity) {
          const object = {
            message: `${values[i]?.name} Only ${values[i]?.returnDetails.length} serail_numbers selected against return quantity ${values[i]?.returnQuantity}`,
          };
          error.push(object);
        }
      }
      setErrors(error);
    }

    function saveLineItems() {
      let flag = true;
      for (let i = 0; i < productsArray.length; i++) {
        if (productsArray[i].returnQuantity < 1) {
          continue;
        }
        if (flag) {
          salesorder_id = productsArray[i]?.salesorder_id;
          flag = false;
        }
        const returnDetail: ReasonNoteArray[] = productsArray[i]?.returnDetails;
        const line_item = {
          item_id: productsArray[i].item_id,
          salesorder_item_id: productsArray[i].line_item_id,
          non_receive_quantity: 0,
          quantity: productsArray[i].returnQuantity,
          warehouse_id: productsArray[i].warehouse_id,
        };
        line_items.push(line_item);
        for (let j = 0; j < returnDetail.length; j++) {
          const custom_field = {
            Product: returnDetail[j].Product,
            Reason: returnDetail[j].Reason,
            Note: returnDetail[j].Note,
            Sku: returnDetail[j].Sku,
          };
          custom_fields.push(custom_field);
        }
      }
    }

    function emptyProductsArray() {
      productsArray = [];
      optionsSelected = [];
      selectedSerialNumber = [];
    }

    return (
      <div>
        <div className="content" id="content">
          <table className="variant_table">
            <tbody>
              <tr className="variant_table_tr">
                <th className="variant_table_th">Date</th>
                <th className="variant_table_th">Name</th>
                <th className="variant_table_th">SKU</th>
                <th className="variant_table_th">Total Quantity</th>
                <th className="variant_table_th">Shiped Quantity</th>
                <th className="variant_table_th">Return Quantity</th>
              </tr>
              {orders &&
                orders?.line_items.map((product: any, index: number) => {
                  saveSelectedQuantity(orders, product);
                  return (
                    <React.Fragment key={index}>
                      <tr className="variant_table_tr">
                        <td className="variant_table_td">
                          <strong>{orders.created_date}</strong>
                        </td>
                        <td className="variant_table_td">
                          <strong>{product.name}</strong>
                          <br />
                          {/* <span>{product.options}</span> */}
                        </td>

                        <td className="variant_table_td">
                          <span>{product.sku}</span>
                        </td>
                        <td className="variant_table_td">
                          <span>{product.quantity}</span>
                        </td>
                        <td className="variant_table_td">
                          <span>
                            {getShippedQuantity(product.line_item_id)}
                          </span>
                        </td>
                        <td className="variant_table_td">
                          <select
                            className="variant_table_quantity"
                            value={getPreviousReturnQuantityValue(
                              product.line_item_id
                            )}
                            onChange={(e: any) => {
                              handleChange(e, product.line_item_id);
                              const object = document.getElementById(
                                `collapse${product.line_item_id}`
                              );

                              if (e.target.value != 0) {
                                if (object) object.style.display = 'revert';
                              } else {
                                if (object) object.style.display = 'none';
                              }
                            }}
                          >
                            <option value={0}>0</option>
                            {showOptions(product.line_item_id).map(
                              (value: string, i: number) => {
                                return (
                                  <option value={i + 1} key={i}>
                                    {i + 1}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </td>
                      </tr>
                      <tr
                        className="collapse"
                        id={`collapse${product.line_item_id}`}
                      >
                        <td
                          colSpan={6}
                          style={{ border: '1px solid #ddd', padding: '8px' }}
                        >
                          <span>Enter Serial Numbers For Return Products</span>
                          <Select
                            mode="multiple"
                            allowClear
                            key={index + product.line_item_id}
                            style={{ width: '100%' }}
                            value={getMultiSelectSelectedValues(
                              product.line_item_id
                            )}
                            placeholder="Please select serial numbers"
                            onChange={(e) =>
                              serialNumberSelectHandleChange(
                                e,
                                product.line_item_id
                              )
                            }
                          >
                            {showSerialNumbers(product.line_item_id).map(
                              (serialNumber: string, index: number) => {
                                return (
                                  <Option
                                    className="demo-option"
                                    key={index + product.line_item_id}
                                    value={parseInt(serialNumber)}
                                    label={parseInt(serialNumber)}
                                    disabled={
                                      getOptionsSelectedLength(
                                        product.line_item_id
                                      ) >
                                      getReturnQuantity(product.line_item_id) -
                                        1
                                        ? getOptionsSelectedSerialNumber(
                                            product.line_item_id
                                          ).includes(parseInt(serialNumber))
                                          ? false
                                          : true
                                        : false
                                    }
                                  >
                                    {/* {serialNumber} */}
                                    <div className="demo-option-label-item">
                                      <span
                                        aria-label={serialNumber}
                                        className="option_items"
                                      >
                                        {serialNumber}
                                      </span>
                                    </div>
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                          {getSelectedSerialNumbers(product.line_item_id)
                            .length > 0 && (
                            <table
                              key={'table' + index + product.line_item_id}
                              className="variant_table2"
                            >
                              <tbody>
                                <tr className="variant_table2_tr">
                                  <th className="variant_table2_th">
                                    Serial Number
                                  </th>
                                  <th className="variant_table2_th">Reason</th>
                                  <th className="variant_table2_th">
                                    Description
                                  </th>
                                </tr>

                                {getSelectedSerialNumbers(
                                  product.line_item_id
                                ).map((serialNumber, i) => {
                                  return (
                                    <tr>
                                      <td className="variant_table2_td">
                                        <span className="serial_number_span">
                                          {serialNumber}
                                        </span>
                                      </td>
                                      <td className="variant_table2_td">
                                        <Input
                                          placeholder={'Return Reason'}
                                          className={"reasons"}
                                          id={`reason_${serialNumber}`}
                                          // value={getReasonValue(
                                          //   product.line_item_id,
                                          //   i,
                                          //   serialNumber
                                          // )}

                                          // onChange={(e) =>
                                          //   handleInputChange(
                                          //     e,
                                          //     product.line_item_id,
                                          //     i,
                                          //     serialNumber
                                          //   )
                                          // }
                                        />
                                      </td>
                                      <td className="variant_table2_td">
                                        <TextArea
                                          // placeholder="Return Note"
                                          id={`note_${serialNumber}`}
                                          className={"descriptions"}
                                          rows={1}
                                          // value={getNoteValue(
                                          //   product.line_item_id,
                                          //   i,
                                          //   serialNumber
                                          // )}

                                          // onChange={(e) =>
                                          //   handleTextAreaChange(
                                          //     e,
                                          //     product.line_item_id,
                                          //     i,
                                          //     serialNumber
                                          //   )
                                          // }
                                          
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
        {errors.length > 0 && (
          <Row
            style={{ marginTop: 20, marginBottom: 20 }}
            className="Error-row"
          >
            <div className="error-block">
              {errors.map((err, index) => {
                return <li key={index}> 🔴 {err.message}</li>;
              })}
            </div>
          </Row>
        )}
        <Row
          style={{ marginTop: 20, marginBottom: 20 }}
          className="verify-form-button-row"
        >
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Button
              type="primary"
              htmlType="submit"
              className="verify-form-button"
              onClick={() => {
                checkReasonNoteArray(productsArray);
                checkReturnQuantity(productsArray);
                if (error.length > 0) {
                  return;
                } else {
                  saveLineItems();
                  const fileReturnObject = {
                    date: new Date().toISOString().split('T')[0],
                    reason:
                      'This Sale Return Created by Swamm Tech for testing purpose',
                    line_items,
                    custom_fields,
                  };
                  if (fileReturnObject?.line_items.length > 0) {
                    goToReview(productsArray);
                  } else {
                    errorAlert('Select at least one item !');
                  }
                  // navigate(`/review`, {
                  //   state: { product: productsArray },
                  // });
                }
              }}
            >
              Preview
            </Button>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}></Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Button
              type="primary"
              htmlType="submit"
              className="verify-form-button"
              onClick={() => {
                checkReasonNoteArray(productsArray);
                checkReturnQuantity(productsArray);
                if (error.length > 0) {
                  return;
                } else {
                  saveLineItems();
                  const fileReturnObject = {
                    date: new Date().toISOString().split('T')[0],
                    reason:
                      'This Sale Return Created by Swamm Tech for testing purpose',
                    line_items,
                    custom_fields,
                  };
                  if (fileReturnObject?.line_items.length > 0) {
                    FileReturn(
                      salesorder_id,
                      fileReturnObject,
                      props?.orders?.customer_name
                    );
                  } else {
                    errorAlert('Select at least one item !');
                  }
                  // navigate(`/review`, {
                  //   state: { product: productsArray },
                  // });
                }
              }}
            >
              File Return
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
);
export default Table;

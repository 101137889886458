import React, { useState } from 'react';
import Spinner from '../components/Spinner/FullPage.spinner';
import Invoice from '../components/Invoice/invoice';
import { useNavigate, useLocation } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
const successAlert = (alert: string) => {
  message.success(alert);
};
const errorAlert = (alert: string) => {
  message.error(alert);
};
const warningAlert = (alert: string) => {
  message.warning(alert);
};
type LocationState = {
  salesReturn: {
    path: string;
  };
  salesReturnDetail: {
    path: string;
  };
};
const Landing: React.FC = () => {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { salesReturn, salesReturnDetail } = location.state as LocationState;
  return (
    <>
      {spinner && <Spinner />}
      <div
        style={{ textAlign: 'center', marginTop: '1em', marginBottom: '8em' }}
      >
        <Invoice
          salesReturn={salesReturn}
          salesReturnDetail={salesReturnDetail}
        />
      </div>
    </>
  );
};

export default Landing;

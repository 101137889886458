import React, { useState } from 'react';
import VerifyOrder from '../components/Form/index';
import Spinner from '../components/Spinner/FullPage.spinner';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
const successAlert = (alert: string) => {
  message.success(alert);
};
const errorAlert = (alert: string) => {
  message.error(alert);
};
const warningAlert = (alert: string) => {
  message.warning(alert);
};
const Landing: React.FC = () => {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  function OrderVerification(email: string, order_no: string, invoice_amount: string) {
    setSpinner(true);
    console.log(`'invoice: ${invoice_amount}'`);
    axios
      .get(`/api/v1/shopify/verify/${email}/${order_no}/${invoice_amount}`)
      .then((response) => {
        setSpinner(false);
        const sales_order = response?.data?.response?.salesorders;
        // console.log('sale orders', sales_order);
        if (sales_order && sales_order.length > 0) {
          successAlert(response?.data?.message);
          navigate(`/returns`, {
            state: { order_id: sales_order[0].salesorder_id },
          });
        }
      })
      .catch((err) => {
        console.log('err: ', err)
        setSpinner(false);
        errorAlert(err?.response?.data?.message);
      });
  }
  return (
    <>
      {spinner && <Spinner />}
      <div
        style={{ textAlign: 'center', marginTop: '1em', marginBottom: '8em' }}
      >
        <h1>2ND LIFE PHONES</h1>
        <h3>
          To start the return process for your order, please enter your email
          address and the order number below.
        </h3>
        <VerifyOrder
          VerifyOrder={(email: string, order_no: string, invoice_amount: string) =>
            OrderVerification(email, order_no, invoice_amount)
          }
        />
      </div>
    </>
  );
};

export default Landing;

import React from 'react';

type PropsType = {
  salesReturnDetail: any;
  totalReturn: (line_items: any) => {};
  ProductDetails: any;
};

const ComponentToPrint: React.FC<PropsType> = React.forwardRef((props) => {
  const { salesReturnDetail, totalReturn, ProductDetails } = props;
  return (
    <>
      <div className="screen_for_print">
        <div id="invoiceholder">
          <div id="invoice" className="effect2">
            <div id="invoice-top">
              <div className="logo">
                <span>2ndLifePhones</span>
                {/* <img src={Logo} alt="Logo" /> */}
              </div>
              <div className="title">
                {/* <span>2ndLifePhone</span> */}

                <h1>
                  {/* RMA Number */}
                  <span className="invoiceVal invoice_num">
                    {salesReturnDetail.salesreturn_number}
                  </span>
                </h1>
                <p>
                  RMA Date:{' '}
                  <span id="invoice_date">{salesReturnDetail.date}</span>
                  <br />
                  {/* RMA Number: <span id="gl_date">9018</span> */}
                </p>
              </div>
              {/*End Title*/}
            </div>
            {/*End InvoiceTop*/}
            <div id="invoice-mid">
              <div id="message">
                {/* <h2>Hello Andrea De Asmundis,</h2>
                <p>
                  An invoice with invoice number #
                  <span id="invoice_num">tst-inv-23</span> is created for{' '}
                  <span id="supplier_name">TESI S.P.A.</span> which is 100%
                  matched with PO and is waiting for your approval.{' '}
                  <a href="javascript:void(0);">Click here</a> to login to view
                  the invoice.
                </p> */}
              </div>
              {/* <div className="cta-group mobile-btn-group">
                <a href="javascript:void(0);" className="btn-primary">
                  Download
                </a>
                <a href="javascript:void(0);" className="btn-default">
                  Cancel
                </a>
              </div> */}
              <div className="clearfix">
                <div className="col-left">
                  <div className="clientlogo">
                    <img
                      src="https://cdn3.iconfinder.com/data/icons/daily-sales/512/Sale-card-address-512.png"
                      alt="Sup"
                    />
                  </div>
                  <div className="clientinfo">
                    <h2 id="supplier">
                      {salesReturnDetail.customer_name.split(',')[0]}
                    </h2>
                    {/* <p>
                      <span id="address">Address</span>
                      <br />
                      <span id="city">City</span>
                      <br />
                      <span id="country">Country</span> -{' '}
                      <span id="zip">Zip</span>
                      <br />
                      <span id="tax_num">Phone Number</span>
                      <br />
                    </p> */}
                  </div>
                </div>
                <div className="col-right">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <span>Owner Name</span>
                          <label id="invoice_total">
                            {salesReturnDetail.customer_name}
                          </label>
                        </td>
                        <td>
                          <span>Sales Order </span>
                          <label id="currency">
                            {salesReturnDetail.salesorder_number}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Total Return</span>
                          <label id="payment_term">
                            {totalReturn(salesReturnDetail.line_items)}
                          </label>
                        </td>
                        <td>
                          <span>Price</span>
                          <label id="invoice_type">
                            ${salesReturnDetail.total}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            padding: '4px',
                            background: '#CCE5FE',
                            color: '#394585',
                          }}
                        >
                          <span>Note :</span>
                          <label id="note">
                            Please attached this invoice with your return
                            packages.
                            {/* What if we show all serial number, reason, note here
                            instead of a new admin panel, so user send it with
                            package in printed form. */}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*End Invoice Mid*/}
            <div id="invoice-bot">
              <div id="table">
                <div
                  className="content"
                  id="content"
                  style={{ marginBottom: '1em' }}
                >
                  {ProductDetails.map((product: any, index: number) => {
                    return (
                      <>
                        <table className="outer_variant_table">
                          <tbody>
                            <tr className="outer_variant_table_tr">
                              <th className="outer_variant_table_th">
                                {product.name}
                              </th>
                              <th className="outer_variant_table_th">
                                SKU : {product.sku}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        <table className="variant_table">
                          <tbody>
                            <tr className="variant_table_tr">
                              <th className="variant_table_th">IMEI</th>
                              <th className="variant_table_th">Reason Name</th>
                              <th className="variant_table_th">Description </th>
                            </tr>
                            {product?.returnDetail.map(
                              (detail: any, index: number) => {
                                return (
                                  <tr className="variant_table_tr">
                                    <td className="variant_table_td">
                                      {detail.Product}
                                    </td>
                                    <td className="variant_table_td">
                                      {detail.Reason}
                                    </td>
                                    <td className="variant_table_td">
                                      {detail.Note}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/*End InvoiceBot*/}
            <footer>
              <div id="legalcopy" className="clearfix">
                <p className="col-right">
                  Our address is:
                  <span className="email">
                    <a>
                      RMA 222 N Pacific Coast Highway, Suite 200, El Segundo,
                      CA, 90245.
                    </a>
                  </span>
                </p>
              </div>
            </footer>
          </div>
          {/*End Invoice*/}
        </div>
      </div>
    </>
  );
});

export default ComponentToPrint;

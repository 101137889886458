export {};

export function removeUnmatched(previousArray: any, newArray: any) {
  console.log(previousArray);
  const thirdArray = previousArray.filter(function (temp: any) {
    return newArray.some(function (temp2: any) {
      // stop and return true at first match
      return temp2.Product === temp.Product;
    });
  });
  return thirdArray;
}
